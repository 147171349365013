import React from "react";
import { Switch, Route } from "react-router-dom";
import Loader from "web-common-ssr/src/views/components/Loader/Loader";
import ErrorBoundary from "../components/ErrorBoundary/ErrorBoundary";
import { SubRoutes } from "./SubRoutes";
import Routes from "./Config";
const PrivateRoutes = React.lazy(() => import("./PrivateRoutes"));
const ErrorPage = React.lazy(() => import("../pages/ErrorPage/ErrorPage"));

export default function Routers() {
  return (
    <ErrorBoundary>
      <React.Suspense fallback={<Loader />}>
        <Switch>
          {Routes.map((route, i) =>
            route.protected ? (
              <PrivateRoutes key={i} {...route} />
            ) : (
              <SubRoutes key={i} {...route} />
            )
          )}
          <Route path="*">
            {" "}
            <ErrorPage />
          </Route>
        </Switch>
      </React.Suspense>
    </ErrorBoundary>
  );
}
