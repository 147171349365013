import {
  FETCH_APPOINTMENTS_SERVICES_SUCCESS,
  FETCH_APPOINEMENTS_STORE_SELECTED_DATA_SUCCESS,
  IS_GEO_LOCATION_ENABLED,
  FETCH_STYLIST_SELECTION_SERVICES_SUCCESS,
  RESET_ALL_SERVICES_REMOVED,
  FETCH_TIMESLOT__DATA_SUCCESS,
  FETCH_TIMESLOT_DATA,
  FETCH_SELECTED_STYLIST,
  FETCH_FAILED_CONFIRMATION_DATE,
  CURRENT_BOOKINGSTATE_UPDATE,
  RESET_APPOINTMENT_CARD_ON_FLOW_CHANGE
} from "../actions/appointments/types";
import {RESET_DATA} from "../actions/types";

const initialState = {
  selectedServices: null,
  allServicesRemoved: false,
  selectedStore: null,
  isGeoLocation: false,
  selectedDate: null,
  timeSlot: null,
  timeSlotLoading: false,
  selectedStylist: null,
  failedConfirmationDate: null,
  bookingstate: null
};

export default function appointmentsReducer(state = initialState, action) {
  switch (action.type) {
    case RESET_ALL_SERVICES_REMOVED:
      return {
        ...state,
        allServicesRemoved: action.data,
      };
    case FETCH_APPOINTMENTS_SERVICES_SUCCESS:
      return {
        ...state,
        selectedServices: action.data,
      };
    case FETCH_APPOINEMENTS_STORE_SELECTED_DATA_SUCCESS:
      return {
        ...state,
        selectedStore: action.data,
      };
    case IS_GEO_LOCATION_ENABLED:
      return {
        ...state,
        isGeoLocation: action.data,
      };
    case FETCH_STYLIST_SELECTION_SERVICES_SUCCESS:
      return {
        ...state,
        selectedDate: action.data,
      };
    case FETCH_TIMESLOT__DATA_SUCCESS:
      return {
        ...state,
        timeSlot: action.data,
        timeSlotLoading: false,
      };
    case FETCH_TIMESLOT_DATA:
      return {
        ...state,
        timeSlot: null,
        timeSlotLoading: true,
      };
    case FETCH_SELECTED_STYLIST:
      return {
        ...state,
        selectedStylist: action.data,
      };

    case FETCH_FAILED_CONFIRMATION_DATE:
      return {
        ...state,
        failedConfirmationDate: action.data,
      };
      case CURRENT_BOOKINGSTATE_UPDATE:
        return {
          ...state,
          bookingstate: action.bookingstate
        }
      case RESET_APPOINTMENT_CARD_ON_FLOW_CHANGE: 
      return{
        ...initialState,
        selectedStore: action.data 
      }
      case RESET_DATA:
      return {...initialState}
    default:
      return state;
  }
}
