import { getNullableType } from "graphql";
import { getResponseData } from "../../middleware/middleware";
import {
  getDashboardUserInfoData,
  fetchDashboardData,
  fetchLogoutDataSuccess,
  fetchDashboardDataSucess,
  fetchDashboardDataFailure,
  getLogoutInfoData,
} from "../Dashboard/dashboardFlyout";
import {
  fetchAppointmentDetailsData,
  fetchAppointmentDetailsDataSuccess,
  fetchAppointmentDetailsDataFailure,
} from "../appointments/appointments";
import {
  FETCH_AUTHENTICATION_DATA,
  FETCH_AUTHENTICATION_DATA_SUCCESS,
  FETCH_AUTHENTICATION_DATA_FAILURE,
  FETCH_AUTHENTICATION_DATA_ERROR,
  FETCH_AUTHENTICATION_PIN_DATA,
  FETCH_AUTHENTICATION_DATA_PIN_SUCCESS,
  FETCH_AUTHENTICATION_DATA_PIN_FAILURE,
  FETCH_AUTHENTICATION_USER_DATA,
  FETCH_AUTHENTICATION_DATA_USER_SUCCESS,
  FETCH_AUTHENTICATION_DATA_USER_FAILURE,
  FETCH_DEEPLINK_USER_DATA,
  FETCH_DEEPLINK_USER_SUCCESS,
  FETCH_DEEPLINK_USER_FAILURE,
  SET_DEEPLINK_LOCATIONID,
  SET_DEEPLINK_USERAGENT,
} from "./types";
import Cookies from "universal-cookie";
export const fetchAuthenticationData = (data) => {
  return {
    type: FETCH_AUTHENTICATION_DATA,
    data,
  };
};
export const fetchAuthenticationDataSuccess = (data) => {
  return {
    type: FETCH_AUTHENTICATION_DATA_SUCCESS,
    data,
  };
};
export const fetchAuthenticationDataFailure = (data) => {
  return {
    type: FETCH_AUTHENTICATION_DATA_FAILURE,
    data,
  };
};
export const fetchAuthenticationDataError = (data) => {
  return {
    type: FETCH_AUTHENTICATION_DATA_ERROR,
    data,
  };
};
export const fetchAuthenticationPinData = (data) => {
  return {
    type: FETCH_AUTHENTICATION_PIN_DATA,
    data,
  };
};
export const fetchAuthenticationPinDataSuccess = (data) => {
  return {
    type: FETCH_AUTHENTICATION_DATA_PIN_SUCCESS,
    data,
  };
};
export const fetchAuthenticationPinDataFailure = (data) => {
  return {
    type: FETCH_AUTHENTICATION_DATA_PIN_FAILURE,
    data,
  };
};
export const fetchAuthenticationUserData = (data) => {
  return {
    type: FETCH_AUTHENTICATION_USER_DATA,
    data,
  };
};
export const fetchAuthenticationUserDataSuccess = (data) => {
  return {
    type: FETCH_AUTHENTICATION_DATA_USER_SUCCESS,
    data,
  };
};
export const fetchAuthenticationUserDataFailure = (data) => {
  return {
    type: FETCH_AUTHENTICATION_DATA_USER_FAILURE,
    data,
  };
};
export const getUserInfoDataSuccess = (data) => {
  return {
    type: FETCH_AUTHENTICATION_DATA_USER_FAILURE,
    data,
  };
};

export const fetchDeepLinkData = (data) => {
  return {
    type: FETCH_DEEPLINK_USER_DATA,
    data,
  };
};
export const fetchDeepLinkDataSuccess = (data) => {
  return {
    type: FETCH_DEEPLINK_USER_SUCCESS,
    data,
  };
};
export const fetchDeepLinkDataaFailure = (data) => {
  return {
    type: FETCH_DEEPLINK_USER_FAILURE,
  };
};
export const setDeepLinkLocationData = (data) => {
  return {
    type: SET_DEEPLINK_LOCATIONID,
    data,
  };
};
export const setDeepLinkUserAgentData = (data) => {
  return {
    type: SET_DEEPLINK_USERAGENT,
    data,
  };
};

export const postAuthenticationData = (args) => async (dispatch) => {
  const { phoneNumber, source, locationId, rememberMe } = args;

  dispatch(fetchAuthenticationData(true));
  const payload = {
    method: "POST",
    url: `/api/session/v1/login`,
    body: {
      phoneNumber: phoneNumber,
      source: source,
      locationId: locationId,
      rememberMe: rememberMe,
    },
  };
  const Postdata = await getResponseData(payload);
  if (Postdata?.status == 200) {
    if (Postdata.data.response.code == 200) {
      dispatch(fetchAuthenticationData(false));
      dispatch(fetchAuthenticationDataFailure(false));
      dispatch(fetchAuthenticationDataSuccess(Postdata));
    } else {
      dispatch(fetchAuthenticationData(false));
      dispatch(fetchAuthenticationDataFailure(Postdata, false));
    }
  } else {
    dispatch(fetchAuthenticationData(false));
    dispatch(fetchAuthenticationDataError(true));
  }
};
export const postAuthenticationPinData = (args) => async (dispatch) => {
  const { phoneNumber, confirmationCode, rememberMe } = args;
  dispatch(fetchAuthenticationPinData(true));
  const payload_pin = {
    method: "POST",
    url: `/api/session/v1/login/validate`,
    body: {
      phoneNumber: phoneNumber,
      confirmationCode: confirmationCode,
      rememberMe: rememberMe,
    },
  };
  const PostPindata = await getResponseData(payload_pin);
  if (PostPindata.status === 200) {
    if (PostPindata.data.response.code == 200) {
      dispatch(fetchAuthenticationPinData(false));
      dispatch(fetchAuthenticationPinDataFailure(false));
      dispatch(fetchAuthenticationPinDataSuccess(PostPindata));
    } else {
      dispatch(fetchAuthenticationPinData(false));
      dispatch(fetchAuthenticationPinDataFailure(PostPindata));
    }
  } else {
    dispatch(fetchAuthenticationPinData(false));
    dispatch(fetchAuthenticationPinDataFailure(PostPindata));
  }
};
export const postAuthenticationUserData = (args) => async (dispatch) => {
  const cookies = new Cookies();
  const refreshToken = cookies.get("refreshToken");
  if (refreshToken) {
    const { firstName, lastname } = args;
    dispatch(fetchAuthenticationUserData(true));

    const payload_user = {
      method: "POST",
      url: `/api/profile/v1/updateProfile`,
      body: {
        firstName: firstName,
        lastName: lastname,
      },
    };
    const PostUserdata = await getResponseData(payload_user);
    if (PostUserdata.status === 200) {
      dispatch(fetchAuthenticationUserData(false));
      dispatch(fetchAuthenticationUserDataFailure(false));
      dispatch(fetchAuthenticationUserDataSuccess(PostUserdata));
    } else {
      dispatch(fetchAuthenticationUserData(false));
      dispatch(fetchAuthenticationUserDataFailure(true));
    }
    dispatch(getDashboardUserInfoData());
  } else {
    dispatch(fetchAuthenticationUserData(false));
    dispatch(fetchAuthenticationUserDataFailure(true));
    dispatch(getLogoutInfoData());
  }
};

export async function getUserInfoData() {
  const cookies = new Cookies();
  const refreshToken = cookies.get("refreshToken");
  const jwt = cookies.get("jwt");

  if (jwt && refreshToken) {
    const payload = {
      method: "GET",
      url: `/api/session/v1/userInfo`,
    };
    const getUserInfodata = await getResponseData(payload);

    if (getUserInfodata?.status === 200) {
      return getUserInfoData;
    } else {
      //TODO: Why do we have this?
      // dispatch(fetchDashboardDataFailure(getUserInfodata?.error));
    }
  }
}

export const postDeepLinkData = (jwtdata) => async (dispatch) => {
  const { jwt, locationId, rememberMe } = jwtdata;
  dispatch(fetchDeepLinkData(true));
  let payload = {};
  if (locationId) {
    payload = {
      method: "GET",
      url: `/api/session/v1/sso?jwt=${jwt}&locationId=${locationId}&rememberMe=${rememberMe}`,
    };
  } else {
    payload = {
      method: "GET",
      url: `/api/session/v1/sso?jwt=${jwt}&rememberMe=${rememberMe}`,
    };
  }
  const Postdata = await getResponseData(payload);
  if (Postdata?.status === 200) {
    //If native app login
    if (
      Postdata?.data?.response?.message ===
      "You will receive a text message, please follow the link given."
    ) {
      dispatch(setDeepLinkUserAgent(true));
      //Else Succesful token/cookie set
    } else {
      const payload = {
        method: "GET",
        url: `/api/session/v1/userInfo`,
      };
      const getUserInfodata = await getResponseData(payload);

      if (getUserInfodata?.status === 200) {
        dispatch(fetchDashboardData(false));
        dispatch(fetchLogoutDataSuccess(null));
        dispatch(fetchDashboardDataSucess(getUserInfodata?.data));
        dispatch(fetchDeepLinkData(false));
        dispatch(fetchDeepLinkDataSuccess(Postdata));
      } else {
        dispatch(fetchDashboardDataFailure(getUserInfodata?.error));
        dispatch(fetchDeepLinkData(false));
        dispatch(fetchDeepLinkDataaFailure(Postdata));
      }
    }
  } else {
    dispatch(fetchDeepLinkData(false));
    dispatch(fetchDeepLinkDataaFailure(Postdata));
    window.location.replace("/");
  }
};

export const getAppointmentDetailsData = (jwt, apptId) => async (dispatch) => {
  //Get JWT first
  dispatch(fetchDeepLinkData(true));
  const payload = {
    method: "GET",
    url: `/api/session/v1/sso?jwt=${jwt}`,
  };
  const Postdata = await getResponseData(payload);

  //If successfully with JWT call for appointments with cookie set
  if (Postdata?.status === 200) {
    dispatch(fetchAppointmentDetailsData(true));
    const payload = {
      method: "GET",
      url: `/api/appointments/v1/details?id=${apptId}`,
    };

    const appointmentGetData = await getResponseData(payload);

    if (appointmentGetData.status === 200) {
      dispatch(fetchAppointmentDetailsData(false));
      dispatch(fetchAppointmentDetailsDataSuccess(appointmentGetData));
    } else {
      dispatch(fetchDeepLinkData(false));
      dispatch(fetchDeepLinkDataaFailure(Postdata));
      dispatch(fetchAppointmentDetailsData(false));
      dispatch(fetchAppointmentDetailsDataFailure(Postdata));
    }
  } else {
    dispatch(fetchDeepLinkData(false));
    dispatch(fetchDeepLinkDataaFailure(Postdata));
    dispatch(fetchAppointmentDetailsData(false));
    dispatch(fetchAppointmentDetailsDataFailure(Postdata));
    window.location.replace("/");
  }
};

export const removeAuthenticationData = () => async (dispatch) => {
  dispatch(fetchAuthenticationPinDataSuccess(null));
  dispatch(fetchAuthenticationDataSuccess(null));
  dispatch(fetchAuthenticationUserDataSuccess(getNullableType));
};

export const setDeepLinkLocation = (data) => (dispatch) => {
  dispatch(setDeepLinkLocationData(data));
};

export const setDeepLinkUserAgent = (data) => (dispatch) => {
  dispatch(setDeepLinkUserAgentData(data));
};
