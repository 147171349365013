export const deleteAppointmentAfterEdit = (appointmentId) => { 
    return JSON.stringify({
        query: `mutation{
        deleteEditedAppointmentMutation
        (appointmentId:${JSON.stringify(appointmentId)}){
          message
        }
      }`,
        variables: {}
      })
    };