export const deleteServiceInAppt = (appointmentId,serviceId) => { 

    return JSON.stringify({
        query: `mutation ($appointmentId:UUID!,$serviceId:UUID!){
            deleteServiceMutation
        (appointmentId:$appointmentId,serviceId:$serviceId){
          message
        }
      }`,
        variables: {"appointmentId": String(appointmentId),"serviceId":String(serviceId)}
      })
    };