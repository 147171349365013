
import { useEffect, useState } from 'react';
import { trackPageView } from '../libs/analytics/client';

export default function useTrackPageView(analyticsPayload) {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    if (!mounted) {
      setMounted(true);
      trackPageView(analyticsPayload);
    }
  }, [analyticsPayload]);
};