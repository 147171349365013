import {
    
    FETCH_PASTAPPOINTMENTS_DATA,
    FETCH_PASTAPPOINTMENTS_DATA_SUCCESS,
    FETCH_PASTAPPOINTMENTS_DATA_FAILURE,
  } from "../actions/Dashboard/types";
  
  const initialState = {
    pastAppointments: null,
    loading: false,
    hasErrors: false,
    locationDetails : null,
  };
  
  export default function pastAppointmentsReducer(state = initialState, action) {
    switch (action.type) {
      case FETCH_PASTAPPOINTMENTS_DATA:
        return { ...state, loading: action.isLoading };
      case FETCH_PASTAPPOINTMENTS_DATA_SUCCESS:
        return {pastAppointments: action.data, loading: action.isLoading, hasErrors: action.error };
      case FETCH_PASTAPPOINTMENTS_DATA_FAILURE:
        return { ...state, loading: action.isLoading, hasErrors: action.errordata };
      default:
        return state;
    }
  }