import {SET_DEEPLINK_USERAGENT} from '../actions/authentication/types';

const initialState = {
    deepLinkUserAgent: null,
  };

  export default function deeplinkUserAgentReducer(state = initialState, action) {
    switch (action.type) {
        case SET_DEEPLINK_USERAGENT:
            return {...state, deepLinkUserAgent: action.data}    
        default:
            return state;
    }
  }