import {SET_DEEPLINK_LOCATIONID} from '../actions/authentication/types';

const initialState = {
    deepLinkLocationInfo: null,
  };

  export default function deeplinkLocationReducer(state = initialState, action) {
    switch (action.type) {
        case SET_DEEPLINK_LOCATIONID:
            return {...state, deepLinkLocationInfo: action.data}
        default:
            return state;
    }
  }