import {
    FETCH_UPCOMING_APPTS_DATA,
    FETCH_UPCOMING_APPTS_DATA_SUCCESS,
    FETCH_UPCOMING_APPTS_DATA_FAILURE,
    DELETE_APPT_DATA,
    DELETE_APPT_DATA_SUCCESS,
    DELETE_APPT_DATA_FAILURE,
    DELETE_APPT_SERVICE_DATA,
    DELETE_APPT_SERVICE_DATA_SUCCESS,
    DELETE_APPT_SERVICE_DATA_FAILURE
  } from "../actions/Dashboard/types";
  
  const initialState = {
    upcomingAppts: null,
    loading: false,
    loadError: null,
    deleting:false,
    deleteError:null,
    deleteMsg:null
  };
  
  export default function upcomingAppointmentsReducer(state = initialState, action) {
    switch (action.type) {
      case FETCH_UPCOMING_APPTS_DATA:
        return { ...state, loading: action.isLoading };
      case FETCH_UPCOMING_APPTS_DATA_SUCCESS:
        return {...state,upcomingAppts: action.data, loading: action.isLoading};
      case FETCH_UPCOMING_APPTS_DATA_FAILURE:
        return { ...state, loading: action.isLoading, loadError: action.errordata };
        case DELETE_APPT_DATA:
          return { ...state, deleting: action.isDeleting };
        case DELETE_APPT_DATA_SUCCESS:
          return {...state,deleteMsg: action.data, deleting: action.isDeleting};
        case DELETE_APPT_DATA_FAILURE:
          return { ...state, deleting: action.isDeleting, deleteError: action.errordata };
          case DELETE_APPT_SERVICE_DATA:
            return { ...state, deleting: action.isDeleting };
          case DELETE_APPT_SERVICE_DATA_SUCCESS:
            return {...state,deleteMsg: action.data, deleting: action.isDeleting};
          case DELETE_APPT_SERVICE_DATA_FAILURE:
            return { ...state, deleting: action.isDeleting, deleteError: action.errordata };
      default:
        return state;
    }
  }