import {
    FETCH_CONFIRMAPPOINTMENT_DATA,
    FETCH_CONFIRMAPPOINTMENT_DATA_SUCCESS,
    FETCH_CONFIRMAPPOINTMENT_DATA_FAILURE
  } from "../actions/appointments/types";
  
  const initialState = {
    appointmentId:null,
    loading: false,
    hasErrors: false,
  };
  
  export default function confirmAppointmentReducer(state = initialState, action) {
    switch (action.type) {
      case FETCH_CONFIRMAPPOINTMENT_DATA:
        return { ...state, loading: action.isLoading };
      case FETCH_CONFIRMAPPOINTMENT_DATA_SUCCESS:
        return {...state,appointmentId: action.data, loading: action.isLoading, hasErrors: action.error };
      case FETCH_CONFIRMAPPOINTMENT_DATA_FAILURE:
        return { ...state, loading: action.isLoading, hasErrors: action.errordata };
      default:
        return state;
    }
  }