import {
    DELETE_APPTSOFTBOOKING_DATA,
    DELETE_APPTSOFTBOOKING_DATA_FAILURE,
    DELETE_APPTSOFTBOOKING_DATA_SUCCESS,
  } from "../actions/appointments/types";
  import {RESET_DATA} from "../actions/types";
  
  const initialState = {
    loading: false,
    hasErrors: false,
  };
  
  export default function deleteApptSoftBookingReducer(state = initialState, action) {
    switch (action.type) {
      case DELETE_APPTSOFTBOOKING_DATA:
        return { ...state, loading: action.loader };
      case DELETE_APPTSOFTBOOKING_DATA_SUCCESS:
        return {...state, loading: action.loader, hasErrors: action.errordata };
      case DELETE_APPTSOFTBOOKING_DATA_FAILURE:
        return { ...state, loading: action.loader, hasErrors: action.errordata };
        case RESET_DATA:
          return {...initialState};
      default:
        return state;
    }
  }