export const reserveAppointment = (
  day,
  startTime,
  source,
  editAppointmentId,
  appointmentDetails,
  locationId
) => {
  // const appointmentDetails = JSON.stringify(appointmentBlocks)
  let appointmentData = [];
  appointmentDetails.forEach((data) => {
    appointmentData.push({ 'serviceId': data.serviceId, 'teammateId': data.teammateId })
  });
  return JSON.stringify({
    query: `mutation (
      $day: String 
      $appointmentBlocks: [JSONObject]
      $editAppointmentId: UUID
      $startTime: String
      $source: String
      $locationId: UUID
      ){
                reserveAppointments(day: $day,
                 startTime: $startTime,
                 source: $source,
                 editAppointmentId: $editAppointmentId,
                 appointmentBlocks: $appointmentBlocks,
                 locationId: $locationId
               ){
                  id
               }
            }
          `,
    variables: {
      day,
      startTime,
      source,
      editAppointmentId : JSON.parse(editAppointmentId),
      appointmentBlocks: appointmentData,
      locationId
    },
  });
};
