import {
    UPDATEUSERLOCATION_DATA,
    UPDATEUSERLOCATION_DATA_SUCCESS,
    UPDATEUSERLOCATION_DATA_FAILURE,
  } from "../actions/location/types";
  
  const initialState = {
    updateUserLocation: null,
    loading: false,
    hasErrors: false,
    locationDetails : null,
  };
  
  export default function updateUserLocationReducer(state = initialState, action) {
    switch (action.type) {
      case UPDATEUSERLOCATION_DATA:
        return { ...state, loading: action.isLoading };
      case UPDATEUSERLOCATION_DATA_SUCCESS:
        return {updateUserLocation: action.data, loading: action.isLoading, hasErrors: action.error };
      case UPDATEUSERLOCATION_DATA_FAILURE:
        return { ...state, loading: action.isLoading, hasErrors: action.errordata };
      default:
        return state;
    }
  }