import {
    FETCH_RESERVEAPPOINTMENTDATA, 
    FETCH_RESERVEAPPOINTMENTDATA_FAILURE, 
    FETCH_RESERVEAPPOINTMENTDATA_SUCCESS
  } from "../actions/appointments/types";
  import {RESET_DATA} from "../actions/types";
  
  const initialState = {
    reservedData: null,
    loading: false,
    hasErrors: false,
  };
  
  export default function reserveAppointmentReducer(state = initialState, action) {
    switch (action.type) {
      case FETCH_RESERVEAPPOINTMENTDATA:
        return { ...state, loading: action.isLoading, reservedData: action.data };
      case FETCH_RESERVEAPPOINTMENTDATA_SUCCESS:
        return { ...state, reservedData: action.data, loading: action.isLoading };
      case FETCH_RESERVEAPPOINTMENTDATA_FAILURE:
        return { ...state, loading: action.isLoading, hasErrors: action.errordata };
        case RESET_DATA:
          return {...initialState}
      default:
        return state;
    }
  }