export const FETCH_AUTHENTICATION_DATA = "FETCH_AUTHENTICATION_DATA";
export const FETCH_AUTHENTICATION_DATA_SUCCESS = "FETCH_AUTHENTICATION_DATA_SUCCESS";
export const FETCH_AUTHENTICATION_DATA_FAILURE = "FETCH_AUTHENTICATION_DATA_FAILURE";
export const FETCH_AUTHENTICATION_DATA_ERROR = "FETCH_AUTHENTICATION_DATA_ERROR";
export const FETCH_AUTHENTICATION_PIN_DATA = "FETCH_AUTHENTICATION_DATA";
export const FETCH_AUTHENTICATION_DATA_PIN_SUCCESS = "FETCH_AUTHENTICATION_DATA_PIN_SUCCESS";
export const FETCH_AUTHENTICATION_DATA_PIN_FAILURE = "FETCH_AUTHENTICATION_DATA_PIN_FAILURE";
export const FETCH_AUTHENTICATION_DATA_PIN_ERROR = "FETCH_AUTHENTICATION_DATA_PIN_ERROR";
export const FETCH_AUTHENTICATION_USER_DATA = "FETCH_AUTHENTICATION_DATA";
export const FETCH_AUTHENTICATION_DATA_USER_SUCCESS = "FETCH_AUTHENTICATION_DATA_USER_SUCCESS";
export const FETCH_AUTHENTICATION_DATA_USER_FAILURE = "FETCH_AUTHENTICATION_DATA_USER_FAILURE";
export const FETCH_DEEPLINK_USER_DATA = "FETCH_DEEPLINK_USER_DATA";
export const FETCH_DEEPLINK_USER_SUCCESS = "FETCH_DEEPLINK_USER_SUCCESS";
export const FETCH_DEEPLINK_USER_FAILURE = "FETCH_DEEPLINK_USER_FAILURE";
export const SET_DEEPLINK_LOCATIONID = "SET_DEEPLINK_LOCATIONID";
export const SET_DEEPLINK_USERAGENT = "SET_DEEPLINK_USERAGENT";
