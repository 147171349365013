export const upcomingAppointments = JSON.stringify({
  query: `query{
      upcomingAppointments
      {
      teamMateid
      firstName
      lastName
      jobId
      jobName
      startAt
      durationSec
      serviceId
      serviceName
      appointmentId
      locationName
      locationId
      categoryId
      categoryName
      categorySlug
      bookedState
      addressLine1
      addressLine2
      addressCity
      addressState
      addressZip
      addressCountry
      geo {
        x
        y
      }
      phoneNumber
      openTime,
      closeTime,
      timeZone,
      organizationId,
      storeNum,
      reservedAt
      reminderSnapshot{
        startAt
        endAt
      }
      }
    }`,
  variables: {},
});
