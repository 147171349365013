import { flatten, clone,uniq } from "lodash"

/**
 * This function will selected stylist and service  in selected List
 * @param {object} data 
 * @param {number} maxLimit 
 * @param {Array} selectedList 
 *  @param {object} selectedServices 
 * @returns 
 */
export const addServiceAndStylist=(data,maxLimit,selectedList,selectedServices)=>{
    let dublicateData=false;
    let existingStylistWithService=[];

    selectedList?.forEach((stylist)=>{
      selectedServices && flatten(Object.values(selectedServices)).forEach((serviceData)=>{
        if(serviceData.TeammateId === stylist.TeammateId ){
          existingStylistWithService.push(serviceData.TeammateId);
        }});
     if(stylist?.TeammateId===data?.TeammateId)
     { dublicateData=true;}
    });
    let existingStylistHasService=uniq(existingStylistWithService);
  if(typeof(selectedList) === "undefined" || selectedList === null ||(selectedList?.length >0 && existingStylistHasService?.length  === 0)){
      return {selectedStylistData:[data]};
   }
    else if(selectedList && selectedList?.length < maxLimit && !dublicateData && existingStylistHasService?.length === 1) {
        return{selectedStylistData:[...selectedList,data]}
    }
    else{
      if(!dublicateData){
      let modifiedArray=[...selectedList];
      let remainingValue=null;
      if(existingStylistHasService?.length === 1){
        remainingValue=modifiedArray.filter((data)=>(data.TeammateId === existingStylistHasService[0]));
        return {selectedStylistData:[...remainingValue,data]}
      }
      else{
        remainingValue=modifiedArray[0];
        let removedStylist=modifiedArray[1].TeammateId
        let remainingServices=removeSelectedServices(removedStylist,selectedServices)
        return {selectedStylistData:[remainingValue,data],remainingServices:remainingServices}
      }
      } 
    }
  }

/**
 * check the last service for stylist or not
 * @param {UUID} TeammateId 
 * @param {Array} selectedServices 
 * @returns 
 */
export const checkLastServiceForStylist = (TeammateId, selectedServices) => {
      let filteredArray=flatten(Object.values(selectedServices)).filter((data)=>(data.TeammateId === TeammateId ));
       return filteredArray?.length === 1;
}

/**
 * returns the appointmentDetails from selectedServices for StylistFlow
 * @param {Array} selectedServices 
 * @returns 
 */
export const getAppointmentDetailsForStylistFlow = (selectedServices) => {
  let appointmentDetails = [];
  selectedServices && Object.keys(selectedServices).forEach( (eachCategory) => {
    const categoryServices = selectedServices[eachCategory];
    categoryServices.forEach( (eachService) => {
      appointmentDetails.push({ teammateId: eachService?.TeammateId, serviceId: eachService?.serviceID})
    })
  })

  return appointmentDetails;
}

/**
 * returns the appointmentDetails from selectedStylist for ServiceFlow
 * @param {Array} selectedStylist 
 * @returns 
 */
export const getAppointmentDetailsForServiceFlow = (selectedStylist) => {
  let appointmentDetails = [];
  selectedStylist?.forEach((stylistData) => {
    appointmentDetails.push({
      serviceId: stylistData?.serviceId,
      teammateId: stylistData?.teammateId
        ? stylistData?.teammateId
        : stylistData?.teamMateid,
    });
  });

  return appointmentDetails;
}
/**
 * returns the fetchServiceDurationFromBookedState from bookedState
 * @param {object} bookedState 
 * @param {UUID} serviceId 
 * @returns 
 */
export const fetchServiceDurationFromBookedState=(bookedState,serviceId)=>{
  let durationMin = null;
  Object.values(bookedState?.services).forEach((data)=> {
        if(data.serviceId === serviceId)
        {
          durationMin=data?.finalDurationSec / 60
        }
      });
    
    return durationMin;
}
/**
 * This function will remove selected Services from appointmentCard
 * @param {uuid} teammateId
 * @param {object} selectedServices 
 * @returns 
 */
export const removeSelectedServices=(teammateId,selectedServices)=>{
  let updatedServices = null;
  const getService = clone(selectedServices);
  const categoryKeys = Object.keys(getService);
  categoryKeys.forEach((eachCategoryKey) => {
    let services = getService[eachCategoryKey];
    let remainingServices = services.filter(
      (service) => service.TeammateId !== teammateId
    );
    if (remainingServices?.length > 0) {
      updatedServices = {
        ...updatedServices,
        [eachCategoryKey]: remainingServices,
      };
    }
  });
  return updatedServices
}
/**
 * This function will check  services For stylist
 * @param {uuid} teammateId
 * @param {object} selectedServices 
 * @returns 
 */
export const checkServiceForStylist=(teammateId,selectedServices)=>{
  let filteredArray=flatten(Object.values(selectedServices)).filter((data)=>(data.TeammateId === teammateId ));
  return filteredArray?.length > 0;
}
/**
 * This function will remove stylist
 * @param {uuid} teammateId
 * @param {object} selectedStylist 
 * @returns 
 */
export const removeStylist=(teammateId,selectedStylist)=>{
  let remainingStylist = selectedStylist.filter(
    (stylist) => stylist.TeammateId !== teammateId
  );
  return remainingStylist;
}
/**
 * This function will check each selected stylist has service
 * @param {object} selectedStylist
 * @param {object} selectedServices 
 * @returns 
 */
export const eachStylistHasService=(selectedStylist,selectedServices)=>{
  let eachSelectedStylistHasService = false;
  selectedServices && selectedStylist?.forEach((stylist) => {
    eachSelectedStylistHasService = checkServiceForStylist(stylist.TeammateId,selectedServices);
  });
 return eachSelectedStylistHasService;
}
/**
 * setNoServiceTeammateId if stylist doesnot have the service then set it as current stylist
 * @param {object} selectedStylist
 * @param {object} selectedServices 
 * @param {uuid} currentTemmateId
 */
export const setNoServiceTeammateId=(currentTemmateId,selectedStylist,selectedServices)=>{
  if(selectedStylist && selectedStylist.length ===1 && selectedServices){
    if(!checkServiceForStylist(currentTemmateId,selectedServices)) return currentTemmateId;
  }else if(selectedStylist && selectedStylist.length >1 && selectedServices){
    let stylistWithNoService=null;
 selectedStylist.forEach((stylist)=>{
if(!checkServiceForStylist(stylist.TeammateId,selectedServices)){
 stylistWithNoService= stylist.TeammateId;
  }
    });
    return stylistWithNoService;
  }else 
  {
    return currentTemmateId;
  }

}

/**
 * checkServiceWithStylist boolean if selected stylist has service or not in stylist flow and return true and false
 * @param {object} selectedStylist 
 * @param {object} getApptObj
 * @param {boolean} isStylistFlow
 */
export const checkServiceWithStylist=(getApptObj,selectedStylist,isStylistFlow)=>{
  return getApptObj?.buttonText ==='Select date & Time' && !getApptObj?.buttonEnable && selectedStylist && isStylistFlow
}