import * as React from "react";
const Login = React.lazy(() =>
  import(/* webpackChunkName: "BellaLogin" */ "../pages/Login/Login")
);
const StoreSearchPage = React.lazy(() =>
  import("../pages/StoreSearchPage/StoreSearchPage")
);

const ServicesPage = React.lazy(() =>
  import("../pages/ServicesPage/ServicesPage")
);

const CalendarPage = React.lazy(() =>
  import("../pages/CalendarPage/CalendarPage")
);

const StylistSelectionPage = React.lazy(() =>
  import("../pages/StylistSelectionPage/StylistSelectionPage")
);
const StylistSelectionCategoryPage = React.lazy(() =>
  import("../pages/StylistSelectionCategoryPage/StylistSelectionCategoryPage")
);

const StylistSelectionCategoryLayout = React.lazy(() =>
  import("../pages/StylistSelectionCategoryLayout/StylistSelectionCategoryLayout")
);

const ExpertStylist = React.lazy(() =>
  import("../pages/ExpertStylist/ExpertStylist")
);

const StoreSelectionPage = React.lazy(() =>
  import("../pages/StoreSelectionPage/StoreSelectionPage")
);

const AppointmentReviewPage = React.lazy(() =>
  import("../pages/AppointmentReviewPage/AppointmentReviewPage")
);

const AppointmentConfirmPage = React.lazy(() =>
  import("../pages/AppointmentConfirmPage/AppointmentConfirmPage")
);
const DashboardPage = React.lazy(() =>
  import("../pages/DashboardPage/DashboardPage")
);
const DashboardPastAppointmentsPage = React.lazy(() =>
  import("../pages/DashboardPastAppointmentsPage/DashboardPastAppointmentsPage")
);
const DashboardPastSalonsPage = React.lazy(() =>
  import("../pages/DashboardPastSalonsPage/DashboardPastSalonsPage")
);

const ServiceSelectionPage = React.lazy(() =>
  import("../pages/ServiceSelectionPage/ServiceSelectionPage")
);

const withoutApptsCalendarPage = React.lazy(() =>
  import("../pages/WithoutApptsCalendarPage/WithoutApptsCalendarPage")
);

const FailedConfirmationPage = React.lazy(() =>
  import("../pages/FailedConfirmationPage/FailedConfirmationPage")
);

const reschedulePage = React.lazy(() =>
  import("../pages/ReSchedulePage/ReSchedulePage")
);
const DeepLinkPage = React.lazy(() =>
  import("../pages/DeepLinkPage/DeepLinkPage")
);
const PicknewdatePage = React.lazy(() =>
  import("../pages/PicknewdatePage/PicknewdatePage")
);

const Routes = [
  {
    path: "/",
    component: DashboardPage,
    exact: true,
    protected: true,
  },
  {
    path: "/login",
    component: Login,
    exact: true,
    protected: false,
  },
  {
    path: "/store/search",
    component: StoreSearchPage,
    exact: true,
    stepper: 1,
    protected: true,
  },
  {
    path: "/store/selection",
    component: StoreSelectionPage,
    exact: true,
    stepper: 1,
    protected: true,
  },
  {
    path: "/services",
    component: ServicesPage,
    exact: true,
    stepper: 2,
    protected: true,
  },
  {
    path: "/services/:serviceName",
    component: ServiceSelectionPage,
    exact: true,
    stepper: 2,
    protected: true,
  },
  {
    path: "/services/detail/:serviceName",
    component: ServiceSelectionPage, //<-- TODO Update to point to new service detail page in future
    exact: true,
    protected: true,
  },
  {
    path: "/calendar/browse",
    component: CalendarPage,
    stepper: 3,
    routes: [
      {
        path: "/calendar/browse/week",
        component: CalendarPage,
      },
      {
        path: "/calendar/browse/month",
        component: CalendarPage,
      },
    ],
    protected: true,
  },
  {
    path: "/booking/dates/search/stylist",
    component: withoutApptsCalendarPage,
    exact: true,
    stepper: 4,
    protected: true,
  },
  {
    path: "/booking/date/reselect",
    component: FailedConfirmationPage,
    exact: true,
    stepper: 4,
    protected: true,
  },
  {
    path: "/picknewdate",
    component: PicknewdatePage,
    protected: true,
  },
  {
    path: "/booking/reschedule/:apptid",
    component: reschedulePage,
    exact: true,
    stepper: 4,
    protected: true,
  },
  {
    path: "/rebook/bookagain/booking/rebook/:apptid",
    component: reschedulePage,
    exact: true,
    protected: true,
  },
  {
    path: "/stylist/selection",
    component: StylistSelectionPage,
    exact: true,
    stepper: 4,
    protected: true,
  },
  {
    path: "/stylist/services",
    component: StylistSelectionCategoryPage,
    exact: true,
    protected: true,
  },
  {
    path: "/stylist/services/:serviceName",
    component: StylistSelectionCategoryLayout,
    exact: true,
    protected: true,
  },
  {
    path: "/stylist/listing",
    component: ExpertStylist,
    exact: true,
    stepper: 4,
    protected: true,
  },
  {
    path: "/appointment/review",
    component: AppointmentReviewPage,
    exact: true,
    stepper: 5,
    protected: true,
  },
  {
    path: "/appointment/confirm",
    component: AppointmentConfirmPage,
    exact: true,
    protected: true,
  },
  {
    path: "/dashboard",
    component: DashboardPage,
    exact: true,
    protected: true,
  },
  {
    path: "/dashboard/pastappointments",
    component: DashboardPastAppointmentsPage,
    exact: true,
    protected: true,
  },
  {
    path: "/dashboard/pastsalons",
    component: DashboardPastSalonsPage,
    exact: true,
    protected: true,
  },
  {
    path: "/bella/deeplink",
    component: DeepLinkPage,
    exact: true,
    protected: false,
  },
];
export default Routes;
