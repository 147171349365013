export const FETCH_NEAR_STORE_DATA = "FETCH_NEAR_STORE_DATA";
export const FETCH_NEAR_STORE_DATA_SUCCESS = "FETCH_NEAR_STORE_DATA_SUCCESS";
export const FETCH_NEAR_STORE_DATA_FAILURE = "FETCH_NEAR_STORE_DATA_FAILURE";

export const FETCH_COORDINATES_REQUEST = "FETCH_COORDINATES_REQUEST";
export const FETCH_COORDINATES_SUCCESS = "FETCH_COORDINATES_SUCCESS";
export const FETCH_COORDINATES_FAILURE = "FETCH_COORDINATES_FAILURE";

export const SET_SESSIONSTORAGE_VALUE = "SET_SESSIONSTORAGE_VALUE";

export const UPDATEUSERLOCATION_DATA = "UPDATEUSERLOCATION_DATA";
export const UPDATEUSERLOCATION_DATA_SUCCESS = "UPDATEUSERLOCATION_DATA_SUCCESS";
export const UPDATEUSERLOCATION_DATA_FAILURE = "UPDATEUSERLOCATION_DATA_FAILURE";