import {
  UPDATEUSERLOCATION_DATA,
  UPDATEUSERLOCATION_DATA_SUCCESS,
  UPDATEUSERLOCATION_DATA_FAILURE,
  } from "../location/types";
  import { getGraphqlResponseData } from "../../middleware/middleware";
  import { updateUserLocation } from "../../graphql/mutations/updateUserLocation"; 
  
  export const updateUserLocationData = (isLoading) => {
    return {
      type: UPDATEUSERLOCATION_DATA,
      isLoading,
    };
  };
  
  export const updateUserLocationDataSucess = (data, isLoading, maxCount) => {
    return {
      type: UPDATEUSERLOCATION_DATA_SUCCESS,
      data,
      isLoading,
    };
  };
  
  export const updateUserLocationDataFailure = (errordata, isLoading, error) => {
    return {
      type: UPDATEUSERLOCATION_DATA_FAILURE,
      errordata,
      isLoading,
      error,
    };
  };
  
  export const setUserLocation = (locationID) => async (dispatch) => {
    // Dispatch Loading state
    dispatch(updateUserLocationData(true));
    const payload = {
      method: "post",
      url: `/graphql`,
      headers: {
        "Content-Type": "application/json",
      },
      data: updateUserLocation(locationID),
    };
    const getgraphqlata = await getGraphqlResponseData(payload);
    if (getgraphqlata.status === 200) {
      // After successfull api call
      dispatch(
        updateUserLocationDataSucess(
          getgraphqlata.data?.data?.updateUserLocation,
          false
        )
      );
    } else {
      dispatch(updateUserLocationDataFailure(getgraphqlata.error));
    }
  };
  