import _ from "lodash";

export const availableStylist = (
  selectedStore,
  selectedServices,
  startDay,
  endDay,
  selectedStylist,
  partoftheday,
  editAppointmentId,
  isStylistFlow,
  restrictTeammate = false
) => {
  let appointmentBlocks = [];
  const getServiceId = (element) => { return element.serviceId || element.serviceID };
  const getTeammateId = (element) => { return element?.teammateId || element?.TeammateId || element?.teamMateid };
  if (selectedStylist && selectedStylist.length > 0 &&  getServiceId(selectedStylist[0])) {
    selectedStylist && selectedStylist?.forEach((element) => {
      appointmentBlocks.push({ 'serviceId': getServiceId(element), 'teammateId': getTeammateId(element) });
    })
  } else {
    selectedServices && _.flatten(Object.values(selectedServices)).forEach((element) => {
      appointmentBlocks.push({ 'serviceId': getServiceId(element), 'teammateId': isStylistFlow ? getTeammateId(element) : null });
    })
  }
 
  let durationBlock = "";
  partoftheday &&
    partoftheday.forEach((each) => {
      durationBlock = `${durationBlock}${JSON.stringify(each)},`;
    });

  return JSON.stringify({
    query: `
      query stylistWithAvailableDays(
        $startDay: String!
        $endDay: String!
        $appointmentBlocks: [JSONObject]
        $timeZone: String!
        $locationId: String!
        $editAppointmentId: String
        $partOfDay: [String]
        $restrictTeammate: Boolean!) {
        stylistWithAvailableDays(
          startDay: $startDay,
          endDay: $endDay,
          appointmentBlocks: $appointmentBlocks,
          locationId: $locationId,
          locationTimezone: $timeZone,
          editAppointmentId: $editAppointmentId,
          partOfDay: $partOfDay,
          restrictTeammate: $restrictTeammate
        ){
          firstName
          lastName
          teammateId
          specialization
          availableDays
          serviceName
          price,
          teammateId,
          imageUrl,
          duration,
          serviceId,
          zone,
          duration
        }
        }`,
    variables: {
      appointmentBlocks: appointmentBlocks,
      startDay,
      endDay: (endDay || startDay),
      timeZone: selectedStore?.timezone,
      locationId: selectedStore?.locationID,
      editAppointmentId: editAppointmentId ? editAppointmentId : null,
      partOfDay: durationBlock ? [durationBlock] : null,
      restrictTeammate: restrictTeammate
    },
  });
};

export const stylistCombo = (
  selectedStore,
  selectedServices,
  startDay,
  timeZone,
  endDay,
  restrictTeammate = false
) => {
  let appointmentBlocks = [];
  typeof selectedServices === "object"
    ? Object.keys(selectedServices).forEach((element) => {
      selectedServices[element].forEach((each) => {
        appointmentBlocks.push(each.serviceID ? each.serviceID : each.serviceId);
      });
    })
    : (appointmentBlocks.push(selectedServices));

    return JSON.stringify({
      query: `
      query stylistCombo(
        $appointmentBlocks: [UUID]
        $startDay: String
        $timeZone: String!
        $locationID: String!
        $restrictTeammate: Boolean!) {
          stylistCombo(
            serviceId:$appointmentBlocks
            date: $startDay
            locationTimezone: $timeZone
            locationId: $locationID
            combo:true
            restrictTeammate: $restrictTeammate
          ){
            lastName
            firstName
            specialization
            serviceId
            price
            teammateId
            serviceId
            serviceName
            imageUrl
          }
      }`,
      variables: {
        appointmentBlocks,
        startDay: startDay,
        timeZone: selectedStore?.timezone ? selectedStore?.timezone : timeZone,
        locationID: selectedStore?.locationID,
        restrictTeammate: restrictTeammate
      },
    });
  };