import {
    FETCH_UPCOMING_APPTS_DATA,
    FETCH_UPCOMING_APPTS_DATA_SUCCESS,
    FETCH_UPCOMING_APPTS_DATA_FAILURE,
    DELETE_APPT_DATA,
    DELETE_APPT_DATA_SUCCESS,
    DELETE_APPT_DATA_FAILURE,
    DELETE_APPT_SERVICE_DATA,
    DELETE_APPT_SERVICE_DATA_SUCCESS,
    DELETE_APPT_SERVICE_DATA_FAILURE
  } from "./types";
  import { upcomingAppointments } from "../../graphql/query/upcomingAppointments";
  import { deleteAppointment } from "../../graphql/mutations/deleteAppointment";
  import { deleteAppointmentAfterEdit } from "../../graphql/mutations/deleteAppointmentAfterEdit";
  import { deleteServiceInAppt } from "../../graphql/mutations/deleteServiceInAppt";
  import { getGraphqlResponseData } from "../../middleware/middleware";
  
  export const fetchUpcomingApptsData = (isLoading) => {
    return {
      type: FETCH_UPCOMING_APPTS_DATA,
      isLoading,
    };
  };
  
  export const fetchUpcomingApptsDataSuccess = (data, isLoading) => {
    return {
      type: FETCH_UPCOMING_APPTS_DATA_SUCCESS,
      data,
      isLoading,
    };
  };
  
  export const fetchUpcomingApptsDataFailure = (errordata, isLoading) => {
    return {
      type: FETCH_UPCOMING_APPTS_DATA_FAILURE,
      errordata,
      isLoading
    };
  };
  
  export const getUpcomingApptsData = () => async (dispatch) => {
    // Dispatch Loading state
    dispatch(fetchUpcomingApptsData(true));
    const payload = {
      method: "post",
      url: `/graphql`,
      headers: {
        "Content-Type": "application/json",
      },
      data: upcomingAppointments,
    };
    const getgraphqldata = await getGraphqlResponseData(payload);
    if (getgraphqldata.status === 200) {
      // After successfull api call
      dispatch(
        fetchUpcomingApptsDataSuccess(
          getgraphqldata.data?.data?.upcomingAppointments,
          false
        )
      );
    } else {
      dispatch(fetchUpcomingApptsDataFailure(getgraphqldata.error,false));
    }
  };
  
  export const deleteApptData = (isDeleting) => {
    return {
      type: DELETE_APPT_DATA,
      isDeleting,
    };
  };
  
  export const deleteApptDataSuccess = (data, isDeleting) => {
    return {
      type: DELETE_APPT_DATA_SUCCESS,
      data,
      isDeleting,
    };
  };
  
  export const deleteApptDataFailure = (errordata, isDeleting) => {
    return {
      type: DELETE_APPT_DATA_FAILURE,
      errordata,
      isDeleting
    };
  };


  export const removeApptData = (apptId, notify, deleteSoftHold,isServiceDeleted) => async (dispatch) => {
    // Dispatch Loading state
    dispatch(deleteApptData(true));
    const payload = {
      method: "post",
      url: `/graphql`,
      headers: {
        "Content-Type": "application/json",
      },
      data: deleteAppointment(apptId, notify, deleteSoftHold,isServiceDeleted),
    };
    const getgraphqldata = await getGraphqlResponseData(payload) || {};
    if (getgraphqldata?.status === 200) {
      // After successfull api call
      dispatch(
        deleteApptDataSuccess(
          getgraphqldata.data?.data?.deleteAppointmentsMutation,
          false
        )
      );
      dispatch(getUpcomingApptsData());
    } else {
      dispatch(deleteApptDataFailure(getgraphqldata.error,false));
    }
  };

  export const removeEditedAppointmentData = (apptId) => async (dispatch) => {
    // Dispatch Loading state
    dispatch(deleteApptData(true));
    const payload = {
      method: "post",
      url: `/graphql`,
      headers: {
        "Content-Type": "application/json",
      },
      data: deleteAppointmentAfterEdit(apptId),
    };
    const getgraphqldata = await getGraphqlResponseData(payload);
    if (getgraphqldata.status === 200) {
      // After successfull api call
      dispatch(
        deleteApptDataSuccess(
          getgraphqldata.data?.data?.deleteEditedAppointmentMutation,
          false
        )
      );
      dispatch(getUpcomingApptsData());
    } else {
      dispatch(deleteApptDataFailure(getgraphqldata.error,false));
    }
  };

  export const deleteApptServiceData = (isDeleting) => {
    return {
      type: DELETE_APPT_SERVICE_DATA,
      isDeleting,
    };
  };
  
  export const deleteApptServiceDataSuccess = (data, isDeleting) => {
    return {
      type: DELETE_APPT_SERVICE_DATA_SUCCESS,
      data,
      isDeleting,
    };
  };
  
  export const deleteApptServiceDataFailure = (errordata, isDeleting) => {
    return {
      type: DELETE_APPT_SERVICE_DATA_FAILURE,
      errordata,
      isDeleting
    };
  };

  export const removeApptServiceData = (apptId,serviceId) => async (dispatch) => {
    // Dispatch Loading state
    dispatch(deleteApptServiceData(true));
    const payload = {
      method: "post",
      url: `/graphql`,
      headers: {
        "Content-Type": "application/json",
      },
      data: deleteServiceInAppt(apptId,serviceId),
    };
    const getgraphqldata = await getGraphqlResponseData(payload) || {};
    if (getgraphqldata?.status === 200) {
      // After successfull api call
      dispatch(
        deleteApptServiceDataSuccess(
          getgraphqldata.data?.data?.deleteServiceMutation,
          false
        )
      );
      dispatch(getUpcomingApptsData());
    } else {
      dispatch(deleteApptServiceDataFailure(getgraphqldata.error,false));
    }
  };