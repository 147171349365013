import {
    FETCH_DEEPLINK_USER_DATA,
    FETCH_DEEPLINK_USER_SUCCESS,
    FETCH_DEEPLINK_USER_FAILURE,
  } from "../actions/authentication/types";
  
  const initialState = {
    loading: false,
    hasErrors: false,
    deepLinkInfo : null,
  };
  
  export default function deeplinkReducer(state = initialState, action) {
    switch (action.type) {
      case FETCH_DEEPLINK_USER_DATA:
        return { ...state, loading: action.data };
      case FETCH_DEEPLINK_USER_SUCCESS:
        return { ...state, deepLinkInfo: action.data, loading: false, hasErrors: false };
      case FETCH_DEEPLINK_USER_FAILURE:
        return { ...state, loading: false, hasErrors: action.data };
      default:
        return state;
    }
  }