/**
 * You can have several &lt;header&gt; elements in one document.The &lt;header&gt; element represents a container for introductory content or a set of navigational links.A &lt;header&gt; element typically contains:one or more heading elements (&lt;h1&gt; - &lt;h6&gt;)logo or icon
 *
 * @module views/Navigation/Header
 */

import React, { useState, useRef, useEffect } from "react";
import Ultalogo from "../../assets/image/Logo.svg";
import backArrow from "../../assets/image/back.svg";
import SalonImage from "../../assets/image/photo.svg";
import UserLogo from "../../assets/image/user-circle.svg";
import Spacer from "web-common-ssr/src/views/components/Spacer/Spacer";
import Image from "web-common-ssr/src/views/components/Image/Image";
import Link_Huge from "web-common-ssr/src/views/components/Link_Huge/Link_Huge";
import InflectionProvider from "web-common-ssr/src/views/__core/InflectionProvider/InflectionProvider";
import Flyout from "web-common-ssr/src/views/components/Flyout/Flyout";
import Button from "web-common-ssr/src/views/components/Button/Button";
import Text from "web-common-ssr/src/views/components/Text/Text";
import { getText } from "../../utils/helper/helper";
import {
  getDashboardUserInfoData,
  getLogoutInfoData,
} from "../../actions/Dashboard/dashboardFlyout";
import FlyoutModal from "./FlyoutModal";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { useHistory, useLocation } from "react-router-dom";
import { useDeviceInflection } from "web-common-ssr/src/views/__core/InflectionProvider/InflectionProvider";
import "./Header.scss";
//import { Redirect } from "react-router-dom";

/**
 * Represents a Header component
 *
 * @method
 * @param {object} props - React properties passed from composition
 * @returns Header
 */
export const Header = (props) => {
  const { breakpoint } = useDeviceInflection();
  // const isSmallDevice = breakpoint.isSmallDevice();
  const { hederObj } = props;
  const history = useHistory();
  const location = useLocation();
  const { headerClass } = hederObj;
  const flyoutRef = useRef();
  const buttonRef = useRef();
  const openFlyout = () => {
    flyoutRef.current.showModal();
    !props.dashboardFlyout?.firstName && props.getDashboardUserInfoData();
  };

  const [isFlyoutModal, setIsFlyoutModal] = useState(false);
  const popupModel = () => {
    if (
      window.location.href.indexOf("dashboard") > -1 ||
      window.location.href.indexOf("appointment/confirm") > -1 ||
      window.location.href.indexOf("store/search") > -1
    ) {
      setIsFlyoutModal(false);
      history.push("/dashboard");
    } else {
      setIsFlyoutModal((prev) => !prev);
    }
    flyoutRef.current.close();
  };
  const closePopup = () => {
    setIsFlyoutModal(false);
  };
  const handleCancelPopup = () => {
    setIsFlyoutModal(false);
    flyoutRef.current.showModal();
  };

  useEffect(() => {
    //Only call if the user is authenticated or logged in

    if (
      props?.isAuthentication &&
      !props.dashboardFlyout?.firstName &&
      !props?.logout?.success
    ) {
      props.getDashboardUserInfoData();
    }
  }, [!props?.dashboardFlyout]);

  // useEffect(() => {
  //   if (
  //     !props?.isAuthentication &&
  //     props.dashboardFlyout?.userType === "P" &&
  //     !props?.logout?.success &&
  //     isSmallDevice
  //   ) {
  //     history.push("/login");
  //   }
  // }, [props?.dashboardFlyout]);

  // logout //
  const logOut = () => {
    props.getLogoutInfoData();
    // flyoutRef.current.close();
    // sessionStorage.clear();
    // localStorage.clear();
  };

  useEffect(() => {
    if (props?.logout?.success) {
      history.push("/login");
      flyoutRef.current.close();
    }
  }, [props?.logout]);

  return (
    <>
      {isFlyoutModal && (
        <FlyoutModal
          closePopup={closePopup}
          handleCancelPopup={handleCancelPopup}
        />
      )}

      {/* {props?.logout?.success && (
         <Redirect
           push
           to={{
             pathname: "/login",
           }}
         />
       )} */}
      <InflectionProvider>
        <header className={`Header-custom ${headerClass ? headerClass : ""}`}>
          <nav className="Header-Nav">
            <div className="Grid">
              <div className="fullGrid">
                <div className="component">
                  <div className="BackArrow">
                    <Link_Huge
                      url="https://www.ulta.com/beautyservices/all"
                      className="back-button"
                    >
                      <img src={backArrow} alt="Exit the Salon" />
                      <span>EXIT THE SALON</span>
                    </Link_Huge>
                  </div>
                </div>
                <div className="component spanMinusTwo">
                  <img
                    src={Ultalogo}
                    alt="Ulta Beauty"
                    className="Image__Responsive"
                  />
                </div>
                <div className="component">
                  {props.dashboardFlyout?.firstName &&
                    props.dashboardFlyout?.userType !== "P" && (
                      <button
                        onClick={() => openFlyout()}
                        className="Button-ds User-circle Button-ds--clear Button-ds--icon"
                      >
                        <img
                          src={UserLogo}
                          title="My Dashboard"
                          alt=" My dashboard"
                          ref={buttonRef}
                        />
                      </button>
                    )}
                </div>
              </div>
            </div>
          </nav>
        </header>

        <Flyout ref={flyoutRef} opener={buttonRef}>
          <div>
            <div className="pastappointments-title">
              <div className="flyout-container">
                <div className="round-img">
                  <Image src={UserLogo} width={56} height={56} />
                </div>
                <div>
                  <Text htmlTag="h5" textStyle="title-5">
                    {getText("dashboard.hi")} {props.dashboardFlyout?.firstName}
                    !
                  </Text>
                  <Spacer title="Spacer" value="01" />
                  <Text htmlTag="p" textStyle="body-1">
                    {props.dashboardFlyout?.phoneNumber}
                  </Text>
                </div>
              </div>
            </div>
            <Spacer title="Spacer" value="02" />
            {!location.pathname.match(/dashboard/) && (
              <Button
                onClick={() => popupModel()}
                label="Go to Dashboard"
                className="gotoDashboard-text"
              />
            )}
            <Spacer title="Spacer" value="03" />
            <div className="log-out-btn">
              <Button
                className="dshOutline"
                label={getText("dashboard.logout")}
                outline
                onClick={() => logOut()}
              />
            </div>
          </div>
        </Flyout>
      </InflectionProvider>
    </>
  );
};

/**
 * Property type definitions
 *
 * @type {object}
 * @property {string} hederObj - Sets label for the menu icon
 */
const propTypes = {
  hederObj: PropTypes.object,
};

Header.propTypes = propTypes;

const mapStateToProps = (state) => ({
  dashboardFlyout: state?.dashboardFlyout?.usrInfo?.response,
  logout: state?.dashboardFlyout?.logout,
  isAuthentication: state?.authentication?.pin,
});
const mapDispatchToProps = (dispatch) => ({
  getDashboardUserInfoData: () => {
    dispatch(getDashboardUserInfoData());
  },
  getLogoutInfoData: () => {
    dispatch(getLogoutInfoData());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
