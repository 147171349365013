import axios from "axios";
import Cookies from "universal-cookie";

// Define the constants
export const URL_REFRESH_TOKEN = "/api/session/v1/login/refreshToken";

// Define the api as axios
const graphqlApiInstance = axios.create({
  withCredentials: true,
});
// Add the interceptor for the response to handle the authentication issues
// This interceptor will check if the response code is 403 and will store the
// current request. On 4013 it will call the refresh token API and try to restore
// the token. On success, we will post the original request again.
graphqlApiInstance.interceptors.response.use(function (response) {
  // Store the original request
  let method = response.config.method;
  let url = response.config.url;
  let data = response.config.data;
  let request = {
    method,
    url,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  const originalReq = request;

  // Call the refresh_token API
  const cookies = new Cookies();
  const refreshToken = cookies.get("refreshToken");

  //If refreshToken is present in cookies then retry this is not login flow otherwise don't retry request
  if (refreshToken) {
    // Check if the response is having error code as 401
    // and is not a retry (to avoid infinite retries) and
    // avoid checking on the authenticate URL as it may be due to user
    // entering wrong password.
    if (response.data.code === 403) {
      return axios
        .post(URL_REFRESH_TOKEN, { refreshToken: refreshToken })
        .then((res) => {
          // If the response is success , then log
          if (res.status === 200) {
            // Log the message
            // Return the original request. ie. retry
            return axios(originalReq);
          }
          // if refresh token fails to send the user back to login TODO add login redirect
        })
        .catch((error) => {
          console.log("inside of error of post");
        });
    }
    // Log

    // If not matched , then return the error
    return Promise.reject(response);
  }
});
export { graphqlApiInstance };
