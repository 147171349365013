import axios from 'axios';
import Cookies from 'universal-cookie';

// Define the constants
export const URL_USER_AUTHENTICATE= "/api/session/v1/login2";
export const URL_REFRESH_TOKEN="/api/session/v1/login/refreshToken";

// Define the api as axios
const apiInstance = axios.create({
    withCredentials:true
});
// Add the interceptor for the response to handle the authentication issues
// This interceptor will check if the response status is 401 and will store the 
// current request. On 401, it will call the refresh token API and try to restore 
// the token. On success, we will post the original request again.
apiInstance.interceptors.response.use(function(response) {
    // For success return the response as is
    return response;
},function(error) {
    // Log the error
    // Store the original request
    const originalReq = error.config;

    // Call the refresh_token API
    const cookies = new Cookies();
    const refreshToken = cookies.get('refreshToken');

    //If refreshToken is present in cookies then retry this is not login flow otherwise don't retry request

    if(refreshToken) {
    // Check if the response is having error code as 401
    // and is not a retry (to avoid infinite retries) and 
    // avoid checking on the authenticate URL as it may be due to user
    // entering wrong password.
    if ( error.response.status == 401 && 
         !originalReq._retry && 
         error.response.config.url !== URL_USER_AUTHENTICATE ) {

        // Set the retry flag to true
        originalReq._retry = true;

        return axios.post(URL_REFRESH_TOKEN,{'refreshToken': refreshToken })
                    .then((res) =>{
                        
                        // If the response is success , then log
                        if ( res.status == 200) {
                            // Return the original request. ie. retry
                            return axios(originalReq);
                        } 
                        // if refresh token fails to send the user back to login TODO add login redirect
                    }).catch((error) => {console.log('inside of error of post')});
    }
    // If not matched , then return the error
    return Promise.reject(error);
}
});
export {apiInstance};