import {
  FETCH_STYLIST_CARD_DATA,
  FETCH_STYLIST_CARD_DATA_SUCCESS,
  FETCH_STYLIST_CARD_DATA_FAILURE,
  FETCH_STYLIST_CARD_DATA_WITHOUT_TIME,
  FETCH_STYLIST_CARD_DATA_UPDATE_ERROR
} from "../actions/stylist/types";
import {RESET_DATA} from "../actions/types";

const initialState = {
  stylistCard: null,
  loading: false,
  hasErrors: false,
  hasTimeAvaialble: false,
  errorMessage: null,
};

export default function stylistReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_STYLIST_CARD_DATA:
      return {
        ...state,
        loading: action.data,
        hasErrors: false,
        errorMessage: null,
      };
    case FETCH_STYLIST_CARD_DATA_SUCCESS:
      return {
        ...state,
        stylistCard: action.data,
        hasErrors: false,
        errorMessage: null,
      };
    case FETCH_STYLIST_CARD_DATA_FAILURE:
      return {
        ...state,
        hasErrors: action.data,
      };
    case FETCH_STYLIST_CARD_DATA_WITHOUT_TIME:
      return {
        ...state,
        hasTimeAvaialble: action.data,
      };
    case FETCH_STYLIST_CARD_DATA_UPDATE_ERROR:
      return {
        ...state,
        hasErrors: action.data,
        errorMessage: action.message,
        hasTimeAvaialble: action.data,
        loading: false,
        stylistCard: null
      };
    case RESET_DATA:
      return { ...initialState }
    default:
      return state;
  }
}
