import AnalyticsClient from './AnalyticsClient';
let client;

const getClient = () => {
  if (!client) {
    client = new AnalyticsClient();
  }
  return client;
}

const trackPageView = (analyticsPayload) => {
  const client = getClient();
  client.trackPageView(analyticsPayload);
}

const trackClick = (analyticsPayload) => {
  const client = getClient();
  client.trackClick(analyticsPayload);
}

export {
  getClient,
  trackClick,
  trackPageView
};