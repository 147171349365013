import {
  FETCH_AUTHENTICATION_DATA,
  FETCH_AUTHENTICATION_DATA_SUCCESS,
  FETCH_AUTHENTICATION_DATA_FAILURE,
  FETCH_AUTHENTICATION_DATA_ERROR,
  FETCH_AUTHENTICATION_PIN_DATA,
  FETCH_AUTHENTICATION_DATA_PIN_SUCCESS,
  FETCH_AUTHENTICATION_DATA_PIN_FAILURE,
  FETCH_AUTHENTICATION_DATA_PIN_ERROR,
  FETCH_AUTHENTICATION_USER_DATA,
  FETCH_AUTHENTICATION_DATA_USER_SUCCESS,
  FETCH_AUTHENTICATION_DATA_USER_FAILURE,
} from "../actions/authentication/types";
const initialState = {
  status: null,
  loading: false,
  hasErrors: false,
  serverIssue:false,
  pin: null,
  pinHasErrors: false,
  PinserverIssue:false,
  user: null,
  userHasError: false,
  hasName: false,
};
export default function authenticationReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_AUTHENTICATION_DATA:
      return {
        ...state,
        loading: action.data,
      };
    case FETCH_AUTHENTICATION_DATA_SUCCESS:
      return {
        ...state,
        status: action.data,
        // hasErrors: false,
      };
    case FETCH_AUTHENTICATION_DATA_FAILURE:
      
      return {
        ...state,
        hasErrors: action.data,
        // hasErrors: true
      };
      case FETCH_AUTHENTICATION_DATA_ERROR:
      return {
        ...state,
        serverIssue:action.data,
      };
      case FETCH_AUTHENTICATION_PIN_DATA:
        return {
          ...state,
          loading: action.data,
        };
      case FETCH_AUTHENTICATION_DATA_PIN_SUCCESS:
        return {
          ...state,
          pin: action.data?.data,
          // hasName : action.data?.data?.user?.hasName,
        };
      case FETCH_AUTHENTICATION_DATA_PIN_FAILURE:
        return {
          ...state,
          // pin: action.data,
          // hasErrors: false,
          pinHasErrors: action.data,
        };
        case FETCH_AUTHENTICATION_DATA_PIN_ERROR:
          return {
            ...state,
            PinserverIssue: action.data,
          };
        case FETCH_AUTHENTICATION_USER_DATA:
          return {
            ...state,
            loading: action.data,
          };
        case FETCH_AUTHENTICATION_DATA_USER_SUCCESS:
          return {
            ...state,
            user: action.data,
            // userHasError:false,
          };
        case FETCH_AUTHENTICATION_DATA_USER_FAILURE:
          return {
            ...state,
            // user: action.data,
            userHasError: action.data,
          };
    default:
      return state;
  }
}