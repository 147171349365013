import { useEffect } from 'react';

export default function useExternalScripts(scriptText){
  useEffect(() => {
    const head = document.querySelector('head');
    const script = document.createElement('script');
    script.text = scriptText;
    head.appendChild(script);
    return () => {
      head.removeChild(script);
    };
  }, [scriptText]);
};