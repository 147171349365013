import {SET_BOOKAGAIN_DATA} from '../actions/Dashboard/types';

const initialState = {
    bookagaindata: null,
  };

  export default function bookAgainReducer(state = initialState, action) {
    switch (action.type) {
        case SET_BOOKAGAIN_DATA:
            return {...state, bookagaindata: action.data}
        default:
            return state;
    }
  }