export const deleteApptSoftBookingMutation = (appointmentId, notify) => {
  return JSON.stringify({
    query: `mutation($appointmentId: String){
            deleteAppointmentSoftHoldBookingMutation
        (appointmentId:$appointmentId){
          message
        }
      }`,
    variables: {"appointmentId": String(appointmentId)},
  });
};
