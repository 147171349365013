import { apiInstance } from './axiosintercept';
import { graphqlApiInstance } from './axiosgraphqlintercept';

const getApiResponse = async ({
  method,
  url,
  token,
  body,
  isLoader = true,
}) => {
  let request = {
    method,
    url,
    headers: {
      "Content-Type": "application/json",
    },
  };
  request = body ? { ...request, data: { ...body } } : request;

 return apiInstance ({
    ...request
  })
};

const getGraphqlResponse = async ({ method, url, data, isLoader = true }) => {
  let request = {
    method,
    url,
    headers: { //TODO Add JWT token, right now the cookie is being set and there is no need to send JWT
      "Content-Type": "application/json",
    },
    data,
  };
  return graphqlApiInstance ({
    ...request
  })
};

export const getResponseData = async (Payload) => {
  try {
    const getdata = await getApiResponse(Payload);
    return getdata;
  } catch (e) {
    return e;
  }
};

export const getGraphqlResponseData = async (Payload) => {
  try {
    const getdata = await getGraphqlResponse(Payload);
    return getdata;
  } catch (e) {
    return e;
  }
};
