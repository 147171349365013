import {
  FETCH_CATEGORY_DATA,
  FETCH_CATEGORY_DATA_SUCCESS,
  FETCH_CATEGORY_DATA_FAILURE,
  FETCH_ADDON_SERVICES_DATA,
  FETCH_ADDON_SERVICES_DATA_SUCCESS,
  FETCH_ADDON_SERVICES_DATA_FAILURE,
  FETCH_SALON_CATEGORY_DATA_SUCCESS
} from "../actions/category/types";
const initialState = {
  addonServices:null,
  addonLoading:false,
  addonError:null,
  services: null,
  loading: false,
  hasErrors: false,
  salon: null
};

export default function categoryReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_CATEGORY_DATA:
      return {
        ...state,
        loading: action.data,
      };
    case FETCH_CATEGORY_DATA_SUCCESS:
      return {
        ...state,
        services: action.data,
      };
    case FETCH_CATEGORY_DATA_FAILURE:
      return {
        ...state,
        hasErrors: action.data,
      };
    case FETCH_ADDON_SERVICES_DATA:
      return {
        ...state,
        addonLoading: action.data,
      };
    case FETCH_ADDON_SERVICES_DATA_SUCCESS:
      return {
        ...state,
        addonServices: action.data,
      };
    case FETCH_ADDON_SERVICES_DATA_FAILURE:
      return {
        ...state,
        addonError: action.data,
      };
      case FETCH_SALON_CATEGORY_DATA_SUCCESS:
        return {
          ...state,
          salon: action.data,
        };
    default:
      return state;
  }
}
