import React, { useState, useRef, useEffect } from "react";
import Spacer from "web-common-ssr/src/views/components/Spacer/Spacer";
import InflectionProvider from "web-common-ssr/src/views/__core/InflectionProvider/InflectionProvider";

import {
  getBannerInfoData,
} from "../../actions/Dashboard/dashboardFlyout";

import { connect } from "react-redux";
import { useDeviceInflection } from "web-common-ssr/src/views/__core/InflectionProvider/InflectionProvider";
import "./Banner.scss";

/**
 * Represents a Banner component
 *
 * @method
 * @param {object} props - React properties passed from composition
 * @returns Banner
 */
export const Banner = (props) => {
  const { breakpoint } = useDeviceInflection();
  const isSmallDevice = breakpoint.isSmallDevice();

  useEffect( ()=>{
    props.getBannerInfoData();
  },[props?.dashboardFlyout]);

  return (
    <>
      <InflectionProvider>
      { props.bannerMessage && (
      <header className="header">
      <div className={isSmallDevice ? "banner-small" : "banner-large"}>
        <div className="container">
        <div className="title">{props.bannerMessage.title}</div>
        {!isSmallDevice && <Spacer title="Spacer" value="01" />}
        <div className="subtitle">{props.bannerMessage.message}</div>
        </div>
      </div>
      </header>
      )}
      </InflectionProvider>

    </>
  );
};


const mapStateToProps = (state) => ({
  bannerMessage: state?.dashboardFlyout?.bannerMessage?.bannerinfo,
});
const mapDispatchToProps = (dispatch) => ({
  getBannerInfoData: () => {
    dispatch(getBannerInfoData());
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(Banner);
