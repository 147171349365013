/**
 * Footer component will be the main Footer Component for POC
 *
 * @module views/components/Footer
 * @memberof -Common
 */
import React from "react";
import PropTypes from "prop-types";
import "./Footer.scss";
import Text from "web-common-ssr/src/views/components/Text/Text";
import Link_Huge from "web-common-ssr/src/views/components/Link_Huge/Link_Huge";

/**
 * Represents a Footer component
 *
 * @method
 * @param {object} props - React properties passed from composition
 * @returns Footer
 */
export const Footer = ({ footerObj }) => {
  const { footerClass } = footerObj;
  return (
    <footer className={`Footer ${footerClass ? footerClass : ""}`}>
      <div className="Footer__wrapper">
        {
          <div className="Footer__bottomWrapper">
            <Text htmlTag="p" textStyle="body-1">
            &copy; Ulta Beauty, Inc. {new Date().getFullYear()}{" "}
            </Text>

            <ul className="Footer__list right-list">
              {
                <>
                  <li className="Footer__listItem">
                    <Link_Huge target="_blank" url={"https://www.ulta.com/company/terms-and-conditions"} secondary>
                      <Text htmlTag="p" textStyle="body-1" color="neutral-600">
                        Terms &amp; Conditions
                      </Text>
                    </Link_Huge>
                  </li>
                  <li className="Footer__listItem">
                    <Link_Huge target="_blank" url={"https://www.ulta.com/company/privacy"} secondary>
                      <Text htmlTag="p" textStyle="body-1" color="neutral-600">
                        Privacy Policy
                      </Text>
                    </Link_Huge>
                  </li>
                </>
              }
            </ul>
          </div>
        }
      </div>
    </footer>
  );
};

/**
 * Property type definitions
 *
 * @type {object}
 * @property {string} footerObj - sets the content class for the Footer
 */
export const propTypes = {
  footerObj: PropTypes.object,
};

Footer.propTypes = propTypes;

export default Footer;
