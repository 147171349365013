import React from "react";
import is from "is_js";
import "../src/components/global/global.scss";
import { BrowserRouter as Router } from "react-router-dom";
import Routers from "./router/Router";
import InflectionProvider from "web-common-ssr/src/views/__core/InflectionProvider/InflectionProvider";
import Layout from "./layouts/Layout";
import { Provider } from "react-redux";
import { store, persistor } from "./store";
import { PersistGate } from "redux-persist/lib/integration/react";
import { useExternalScripts } from "./hooks";
import {
  quantumMetricScript,
  tealiumScript
} from "./libs";

export const App = () => {
  useExternalScripts(tealiumScript());
  useExternalScripts(quantumMetricScript());

  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
      <Router>
        <InflectionProvider
          baseInflection={`${
            is.desktop() && !is.tablet() ? "Mobile" : "Desktop"
          }`}
        >
          <Layout
            classes="mainLogiContainer"
            hederObj={{
              isHeader: true,
              headerClass: "login_header",
            }}
            footerObj={{
              isFooter: true,
              footerClass: "login_footer",
              showAppointmentCardFooter: false,
            }}
          >
              <Routers />       
          </Layout>
        </InflectionProvider>
        </Router>
      </PersistGate>
    </Provider>
  )
};