export default class AnalyticsClient {
  constructor(tagManager) {
    try {
      if (!tagManager) {
        this.tagManager = window.utag;
      }
      else {
        this.tagManager = tagManager;
      }
    }
    catch(e) {
      console.log(e);
    }
  }

  trackPageView(utagData) {
    if (this.tagManager) {
      this.tagManager.view(utagData);
    }
  }

  trackClick(utagData) {
    if (this.tagManager) {
      this.tagManager.link(utagData);
    }
  }
}