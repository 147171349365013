import { getResponseData } from "../../middleware/middleware";

import {
  FETCH_CATEGORY_DATA,
  FETCH_CATEGORY_DATA_SUCCESS,
  FETCH_CATEGORY_DATA_FAILURE,
  FETCH_ADDON_SERVICES_DATA,
  FETCH_ADDON_SERVICES_DATA_SUCCESS,
  FETCH_ADDON_SERVICES_DATA_FAILURE,
  FETCH_SALON_CATEGORY_DATA_SUCCESS,
} from "./types";

import { setSelectedStoreData } from "../appointments/appointments";

export const fetchCategoryData = (data) => {
  return {
    type: FETCH_CATEGORY_DATA,
    data,
  };
};

export const fetchCategorysDataSucess = (data) => {
  return {
    type: FETCH_CATEGORY_DATA_SUCCESS,
    data,
  };
};

export const fetchCategorysDataFailure = () => {
  return {
    type: FETCH_CATEGORY_DATA_FAILURE,
  };
};

export const fetchSalonCategorysDataSucess = (data) => {
  return {
    type: FETCH_SALON_CATEGORY_DATA_SUCCESS,
    data,
  };
};

export const getCategoryData = (args) => async (dispatch) => {
  if (args) {
    const { locationID, categoryID } = args;
    dispatch(fetchCategoryData(true));
    const payload = {
      method: "GET",
      url: `/api/catalog/v2/location/${locationID}/category/${categoryID}/services`,
    };
    const getdata = await getResponseData(payload);

    if (getdata?.status === 200) {
      dispatch(fetchCategoryData(false));
      dispatch(fetchCategorysDataSucess(getdata.data));
    } else {
      dispatch(fetchCategoryData(false));
      dispatch(fetchCategorysDataFailure(getdata));
    }
  } else {
    dispatch(fetchCategorysDataSucess(null));
  }
};

export const removeCategoryData = () => async (dispatch) => {
  getCategoryData(null);
};

export const fetchAddonServicesData = (data) => {
  return {
    type: FETCH_ADDON_SERVICES_DATA,
    data,
  };
};

export const fetchAddonServicesDataSuccess = (data) => {
  return {
    type: FETCH_ADDON_SERVICES_DATA_SUCCESS,
    data,
  };
};

export const fetchAddonServicesDataFailure = (data) => {
  return {
    type: FETCH_ADDON_SERVICES_DATA_FAILURE,
    data,
  };
};

export const getAddonServicesData = (args) => async (dispatch) => {
  if (args) {
    const { locationID, categoryID } = args;
    dispatch(fetchAddonServicesData(true));
    const payload = {
      method: "GET",
      url: `/api/catalog/v2/location/${locationID}/category/${categoryID}/services`,
    };
    const getdata = await getResponseData(payload);

    if (getdata?.status === 200) {
      dispatch(fetchAddonServicesData(false));
      dispatch(fetchAddonServicesDataSuccess(getdata.data));
    } else {
      dispatch(fetchAddonServicesData(false));
      dispatch(fetchAddonServicesDataFailure(getdata));
    }
  } else {
    dispatch(fetchAddonServicesDataSuccess(null));
  }
};

export const getsalonCategoryData = (categoryLocationID) => async (dispatch) => {
    dispatch(fetchCategoryData(true));
    const payload = {
      method: "GET",
      url: `/api/catalog/v2/location/${categoryLocationID}/categories`,
    };
    const getdata = await getResponseData(payload);

    if (getdata?.status === 200) {
      const getdatas = {
        ...getdata?.data?.storeDetails,
        locationID: categoryLocationID,
        categories: getdata?.data?.categories.map((each) => ({
          categoryID: each?.id,
          ...each,
        })),
      };
      dispatch(fetchCategoryData(false));
      dispatch(setSelectedStoreData(getdatas));
    } else {
      dispatch(fetchCategorysDataFailure(getdata));
      dispatch(fetchCategoryData(false));
    }
};


export const getCategoryDataByStoreNum = (storeNum) => async (dispatch) => {
  dispatch(fetchCategoryData(true));
  const payload = {
    method: "GET",
    url:`/api/catalog/v2/store/${storeNum}`,
  };
  const getdata = await getResponseData(payload);

  if (getdata?.status === 200) {
    const getdatas = {
      ...getdata?.data?.storeDetails,
      locationID: getdata?.data?.storeDetails?.id,
      categories: getdata?.data?.categories.map((each) => ({
        categoryID: each?.id,
        ...each,
      })),
    };
    dispatch(fetchCategoryData(false));
    dispatch(setSelectedStoreData(getdatas));
  } else {
    dispatch(fetchCategorysDataFailure(getdata));
    dispatch(fetchCategoryData(false));
  }
};

/**
 * @typedef {function} fetchServiceForStylistFlow
 *
 * @param {UUID} categoryID 
 *  @param {UUID} locationID
 *  @param {UUID} stylistID
 */
export const fetchServiceForStylistFlow = (args) => async (dispatch) => {
      const{categoryID,locationID, stylistID}=args
      dispatch(fetchCategoryData(true));
      const payload = {
          method: "GET",
          url: `/api/stylist/v2/category/${categoryID}/location/${locationID}/stylist/${stylistID}/services`,
        };
        const getdata = await getResponseData(payload);
        if (getdata?.status === 200){
          dispatch(fetchCategoryData(false));
          dispatch(fetchCategorysDataSucess(getdata.data));
        } else {
          dispatch(fetchCategoryData(false));
          dispatch(fetchCategorysDataFailure(getdata));
        }
};

export const fetchAddonServicesForStylistFlow = (args) => async (dispatch) => {
  if (args) {
    const { locationID, categoryID, stylistID } = args;
    dispatch(fetchAddonServicesData(true));
    const payload = {
      method: "GET",
      url: `/api/stylist/v2/category/${categoryID}/location/${locationID}/stylist/${stylistID}/services`,
    };
    const getdata = await getResponseData(payload);

    if (getdata?.status === 200) {
      dispatch(fetchAddonServicesData(false));
      dispatch(fetchAddonServicesDataSuccess(getdata.data));
    } else {
      dispatch(fetchAddonServicesData(false));
      dispatch(fetchAddonServicesDataFailure(getdata));
      console.log("error", getdata);
    }
  } else {
    dispatch(fetchAddonServicesDataSuccess(null));
  }
};