import { deleteApptSoftBookingMutation } from "../../graphql/mutations/deleteApptSoftBookingMutation";
import { getGraphqlResponseData } from "../../middleware/middleware";
import {
  DELETE_APPTSOFTBOOKING_DATA,
  DELETE_APPTSOFTBOOKING_DATA_FAILURE,
  DELETE_APPTSOFTBOOKING_DATA_SUCCESS,
} from "../appointments/types";

export const deleteApptSoftBookiData = (loader)=>{
    return{
        type: DELETE_APPTSOFTBOOKING_DATA,
        loader,
    }
}

export const deleteApptSoftBookingDataSuccess = (loader, errordata) => {
  return {
    type: DELETE_APPTSOFTBOOKING_DATA_SUCCESS,
    loader,
    errordata
  };
};

export const deleteApptSoftBookingDataFailure = (errordata, loader) => {
  return {
    type: DELETE_APPTSOFTBOOKING_DATA_FAILURE,
    errordata,
    loader,
  };
};
export const deleteApptSoftBooking = (apptId) => async (dispatch) => {
  // Dispatch Loading state
  dispatch(deleteApptSoftBookiData(true))
  const payload = {
    method: "post",
    url: `/graphql`,
    headers: {
      "Content-Type": "application/json",
    },
    data: deleteApptSoftBookingMutation(apptId),
  };
  const getgraphqldata = await getGraphqlResponseData(payload);
  if (getgraphqldata.status === 200) {
    // After successfull api call
    dispatch(
      deleteApptSoftBookingDataSuccess(
        false,
        false
      )
    );
  } else {
    dispatch(deleteApptSoftBookingDataFailure(getgraphqldata.error, false));
  }
};
