import {
  FETCH_NEAR_STORE_DATA,
  FETCH_NEAR_STORE_DATA_SUCCESS,
  FETCH_NEAR_STORE_DATA_FAILURE,
  FETCH_COORDINATES_REQUEST,
  FETCH_COORDINATES_SUCCESS,
  FETCH_COORDINATES_FAILURE,
  SET_SESSIONSTORAGE_VALUE
} from "../actions/location/types";

const initialState = {
  Stores: null,
  loading: false,
  hasErrors: false,
  locationDetails : null,
  sessionStore: false,
  maxCount: null
};

export default function locationReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_NEAR_STORE_DATA:
      return { ...state, loading: action.isLoading };
    case FETCH_NEAR_STORE_DATA_SUCCESS:
      return {Stores: action.data, loading: action.isLoading, hasErrors: action.error };
    case FETCH_NEAR_STORE_DATA_FAILURE:
      return { ...state, loading: action.isLoading, hasErrors: action.errordata };
      case FETCH_COORDINATES_REQUEST:
        return{ ...state, loading: action.isLoading };
        case FETCH_COORDINATES_SUCCESS:
          return{...state,loading: action.isLoading , locationDetails: action.payload };
          case FETCH_COORDINATES_FAILURE:
            return{...state, loading: action.isLoading , hasErrors: action.payload};
            case SET_SESSIONSTORAGE_VALUE:
              return{...state, loading: action.isLoading , sessionStore: action.payload};
    default:
      return state;
  }
}
