import {
    FETCH_PREVIOUSSALONVISITED_DATA,
    FETCH_PREVIOUSSALONVISITED_DATA_SUCCESS,
    FETCH_PREVIOUSSALONVISITED_DATA_FAILURE
  } from "../actions/Dashboard/types";
  
  const initialState = {
    previousSalonVisited: null,
    loading: false,
    hasErrors: false,
    locationDetails : null,
  };
  
  export default function previousSalonVisitedReducer(state = initialState, action) {
    switch (action.type) {
      case FETCH_PREVIOUSSALONVISITED_DATA:
        return { ...state, loading: action.isLoading };
      case FETCH_PREVIOUSSALONVISITED_DATA_SUCCESS:
        return {previousSalonVisited: action.data, loading: action.isLoading, hasErrors: action.error };
      case FETCH_PREVIOUSSALONVISITED_DATA_FAILURE:
        return { ...state, loading: action.isLoading, hasErrors: action.errordata };
      default:
        return state;
    }
  }