export const updateUserLocation = (locationID) => { 

return JSON.stringify({
    query: `mutation ($locationId: UUID!){
      updateUserLocation(locationId: $locationId){
      id
      LocationId
      UserId
      message
    }
  }`,
    variables: {"locationId": String(locationID)}
  })
};