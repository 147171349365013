import {
  FETCH_STORE_SCHEDULE_OVERRIDE_FAILURE, 
  FETCH_STORE_SCHEDULE_OVERRIDE_REQUEST, 
  FETCH_STORE_SCHEDULE_OVERRIDE_SUCCESS
} from "../actions/scheduleOverrides/types";

const initialState = {
  data: [],
  loading: false,
  error: null,
};

export default function scheduleOverridesReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_STORE_SCHEDULE_OVERRIDE_REQUEST:
      return { ...state, loading: true };
    case FETCH_STORE_SCHEDULE_OVERRIDE_SUCCESS:
      return { 
        ...state, 
        data: action.data?.filter((storeOverride) => storeOverride.isClosed)
                .map((closedStoreOverride) => closedStoreOverride?.date), 
        loading: false
      };
    case FETCH_STORE_SCHEDULE_OVERRIDE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      }
    default:
      return state;
  }
}