import { reserveAppointment } from "../../graphql/mutations/reserveAppointment";
import { getGraphqlResponseData } from "../../middleware/middleware";
import {
  FETCH_RESERVEAPPOINTMENTDATA,
  FETCH_RESERVEAPPOINTMENTDATA_FAILURE,
  FETCH_RESERVEAPPOINTMENTDATA_SUCCESS,
} from "../../actions/appointments/types";

export const fetchReserveAppointmentnData = (isLoading, data) => {
  return {
    type: FETCH_RESERVEAPPOINTMENTDATA,
    isLoading,
    data,
  };
};

export const fetchReserveAppointmentnDataSucess = (data, isLoading) => {
  return {
    type: FETCH_RESERVEAPPOINTMENTDATA_SUCCESS,
    data,
    isLoading,
  };
};

export const fetchReserveAppointmentnDataFailure = (errordata, isLoading) => {
  return {
    type: FETCH_RESERVEAPPOINTMENTDATA_FAILURE,
    errordata,
    isLoading,
  };
};

export const setReserveAppointment =
  (day, startTime, source, editAppointmentId, appointmentDetails, locationId) =>
  async (dispatch) => {
    // Dispatch Loading state
    dispatch(fetchReserveAppointmentnData(true, null));
    const payload = {
      method: "post",
      url: `/graphql`,
      headers: {
        "Content-Type": "application/json",
      },
      data: reserveAppointment(
        day,
        startTime,
        source,
        editAppointmentId,
        appointmentDetails,
        locationId
      ),
    };
    const getgraphqlata = await getGraphqlResponseData(payload);
    if (
      // getgraphqlata.status === 200 &&
      getgraphqlata?.data?.data?.reserveAppointments
    ) {
      // After successfull api call
      dispatch(
        fetchReserveAppointmentnDataSucess(
          getgraphqlata.data.data.reserveAppointments,
          false
        )
      );
    } else {
      dispatch(
        fetchReserveAppointmentnDataFailure(
          getgraphqlata?.data?.errors[0]?.message ||
            getgraphqlata?.data?.errors,
          false
        )
      );
    }
  };

export const removeReserveAppointment = () => async (dispatch) => {
  dispatch(fetchReserveAppointmentnDataSucess(null, false));
};
